import React, { Component } from 'react'
import Page from '../../containers/page'

import { Container, Row, Col, Input, Button } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import Search from '../../components/search'
import { Link } from 'react-router-dom'

import ListBuilder from '../../components/listBuilder'
import DeleteModal from '../../components/deleteModal'
import PreviewModal from '../../components/previewModalLog'

import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../../helpers/linkHelper'
import { API_ENDPOINT } from '../../constants'

import garabageIcon from '../../assets/svg/garabage.svg'
import userIcon from '../../assets/svg/user-icon.svg'
import notification from '../../assets/svg/notification.svg'
import profile from '../../assets/svg/profile-image.svg'
import moreIcon from '../../assets/svg/more.svg'
import map from '../../assets/svg/map.svg'
import chart from '../../assets/svg/chart.svg'
import editIcon from '../../assets/svg/edit.svg'

import moment from 'moment';

class List extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            newsData: [],
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
        if (this.props.uData.menuObjects) {
            let found = false;
            for (let i = 0; i < this.props.uData.menuObjects.length; i++) {

                if (String(this.props.uData.menuObjects[i].uniqueID) == "2") {
                    found = true;
                }
            }

            if (!found) {
                this.props[0].history.push('/')

            }
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        this.get()
    }



    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }

    delete = (id) => {
        // this.setState(
        //     {
        //         loading: true,
        //     },
        //     () => {
        //         fetch(API_ENDPOINT + '/data/menu/' + id, {
        //             method: 'DELETE',
        //             headers: {
        //                 'content-type': 'application/json',
        //                 Authorization:
        //                     typeof localStorage !== 'undefined'
        //                         ? `Bearer ${localStorage.getItem('authToken')}`
        //                         : null,
        //             },
        //         })
        //             .then((res) => res.json())
        //             .then((result) => {
        //                 this.get()
        //             })
        //     }
        // )
    }

    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }


        return (
            <div className='page'>
                <Container className='dashboard'>
                    <Row>
                        <Col lg="3">
                            <Input type="date"
                                value={this.props[0].match.params.date != '0' ? this.props[0].match.params.date : null}
                                onChange={(e) => {
                                    let newUrl = "/" + this.props[0].location.pathname.split('/')[1] + "/" + this.props[0].location.pathname.split('/')[2] + "/" + this.props[0].location.pathname.split('/')[3] + "/" + e.target.value;
                                    if (typeof window != 'undefined') {
                                        window.location.href = newUrl;
                                    }
                                }} />
                        </Col>
                        <Col lg='12'>
                            {/* <Search updateParams={this.updateParams} /> */}

                            <div className='panel'>
                                <div className='main-table-header'>
                                    <h5 className='component-header'>Lista logova</h5>
                                    {/* <button className='add-news'>
                                        <Link to='/menu/new'>
                                            Dodaj meni
                                        </Link>
                                    </button> */}
                                </div>

                                <ListBuilder
                                    loading={this.state.loading && false}
                                    total={this.state.total}
                                    showNumeration={false}
                                    logsTable={true}
                                    fields={[
                                        {
                                            type: 'text', class: 'labelbam', name: 'username', sortLabel: 'Po imenu', allowSort: true,

                                        },
                                        {
                                            type: 'text', class: 'labelbam', name: 'path', sortLabel: 'Po imenu', allowSort: true,

                                        },
                                        {
                                            type: 'text', class: 'labelbam', name: 'date', sortLabel: 'Po imenu', allowSort: true,

                                        },
                                        // {
                                        //     type: 'text', class: 'labelbam', name: 'statusText', sortLabel: 'Po imenu', allowSort: true,

                                        // },

                                    ]}
                                    rawItems={this.state.items}
                                    items={


                                        this.state.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                date: moment.unix(item.timestamp).format("DD.MM.YYYY. HH:mm:ss"),
                                                path: item.path == '/vote' || item.path == '/contact' || item.path == '/absence' || item.path == '/login' || item.path == '/logout' || item.type == 'download' || item.path == '/absence/delete' ? item.name : <a href={item.path} target="_blank">{item.path}</a>,
                                                hrefAllow: !item.href ? false : true
                                            }

                                        })

                                    }
                                    actions={[
                                        // {
                                        //     type: 'functionAction',
                                        //     render: (item) =>
                                        //         // console.log(item)
                                        //         item.status == 0 ?
                                        //             <>
                                        //                 <Button className="red-button" onClick={() => this.changeStatus(item, 2)}>ODBIJ</Button>
                                        //                 <Button className="green-button" onClick={() => this.changeStatus(item, 1)}>ODOBRI</Button>
                                        //             </>
                                        //             :
                                        //             item.status == 1 ?
                                        //                 <Button className="red-button" onClick={() => this.changeStatus(item, 2)}>ODBIJ</Button>
                                        //                 :
                                        //                 <Button className="green-button" onClick={() => this.changeStatus(item, 1)}>ODOBRI</Button>

                                        // },
                                        {
                                            component: <Isvg src={moreIcon} className className='edit-icon' />,
                                            onClick: (item) => this.setState({ previewModal: item })
                                        },

                                        // {
                                        //     type: 'functionAction',
                                        //     render: (item) => <Link to={
                                        //         `/menu/${item._id}`
                                        //     }><Isvg src={editIcon} />
                                        //     </Link>,
                                        // },
                                        // {
                                        //     component: <Isvg src={garabageIcon} className className='edit-icon' />,
                                        //     onClick: (item) => this.setState({ deleteModal: item })
                                        // },
                                    ]}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    Obrisi <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                  </DeleteModal>
                                <PreviewModal
                                    isOpen={this.state.previewModal}
                                    toggle={() => this.setState({ previewModal: null })}
                                    handler={() => { this.delete(this.state.previewModal._id); this.setState({ previewModal: null }) }}
                                    item={this.state.previewModal}
                                >
                                    {/* Obrisi <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>? */}
                                </PreviewModal>

                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(List)
