import React, { Component } from 'react'

import Isvg from 'react-inlinesvg'
import { Pie, Line } from 'react-chartjs-2';
import Page from '../containers/page'

import { Container, Row, Col, Input } from 'reactstrap'

import { Link, Redirect } from 'react-router-dom';


import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper'

import Navigation from '../components/navigation'

// import profile from '../assets/svg/profile-image.svg';
import profile from '../assets/no-image.png';
import map from '../assets/svg/map.svg'
import chart from '../assets/svg/chart.svg';
import deskIcon from '../assets/svg/desk-icon.svg';
import warnningIcon from '../assets/svg/warnning-icon.svg';
import chartIcon from '../assets/svg/chart-icon.svg';
import mailIcon from '../assets/svg/mail-icon.svg';
import arrowRight from '../assets/svg/arrow-right.svg'
import searchIcon from '../assets/svg/search.svg'
import weatherIcon from '../assets/svg/weather-icon.svg'
import arrowUp from '../assets/svg/arrow-up.svg'
import arrowDown from '../assets/svg/arrow-down.svg'

import moment from 'moment';

import logo from '../assets/logo.svg';
import zajednica from '../assets/zajednica.svg';

import { API_ENDPOINT } from '../constants'

var striptags = require('striptags')

class HomePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      promotedProjects: [],
      ...props.initialData,
      form: false,

      useState: false,
      bannerForm: false,
      params: {},
      areas: [],
      news: [],
      weatherId: 0,
      logsData: [],
      userData: [],
      logsDate: [],
      cities: [
        '3204541',
        '3204186',
        '3201984',
        '3188893',
        '3191281',
        '3188582',
        '3194828',
        '3204222',
        '3192409',
        '3199779',
        '3203521',
        '3186423',
        '3186573',
        '3203099',
        '3200837',
        '3187609'
      ],
      startDay: moment.unix(Math.floor(new Date().setTime(new Date().getTime() - (6 * 24 * 60 * 60 * 1000)) / 1000)).format('YYYY-MM-DD'),
      endDay: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD'),
      selectedPosition: null
    }
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
    fetch(API_ENDPOINT + '/data/homepage/users/positions', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization:
          typeof localStorage !== 'undefined'
            ? `Bearer ${localStorage.getItem('authToken')}`
            : null,
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          this.setState({ positions: result })
        }

      })

    this.getStats()


  }
  getStats = () => {
    let data = {
      startDay: this.state.startDay,
      endDay: this.state.endDay,
      position: this.state.selectedPosition
    }
    fetch(API_ENDPOINT + '/homepage/data/logs/stats', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization:
          typeof localStorage !== 'undefined'
            ? `Bearer ${localStorage.getItem('authToken')}`
            : null,
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((result) => {
        if (result && result.length) {
          let logsData = [];
          let userData = [];
          let logsDate = [];
          for (let i = result.length - 1; i >= 0; i--) {
            logsData.push(result[i].allLogs);
            userData.push(result[i].userLogs);
            logsDate.push(result[i].date)
          }
          this.setState({ logsStats: result, logsData, userData, logsDate })
        }

      })
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get()
        }
      )
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    this.get()

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
    this.getWeather();
  }

  toggle = () => {
    this.setState({
      form: !this.state.form,
    })
  }


  getWeather = () => {
    fetch('https://api.openweathermap.org/data/2.5/weather?id=' + this.state.cities[this.state.weatherId] + '&appid=ead872841a3f922859ce83fabb5b5e39')
      .then(res => res.json()).then((result) => {
        // console.log(result);
        let icon_path = `https://openweathermap.org/img/w/` + result.weather[0].icon + `.png`;
        this.setState({
          city: result.name,
          temp: Math.round(result.main.temp - 273),
          weatherIcon: icon_path
        })
      })
      .catch(function () {
      });
  }
  decWeatherId = () => {
    if (this.state.weatherId > 0) {
      this.setState({ weatherId: this.state.weatherId - 1 }, () => this.getWeather())
    } else {
      this.setState({ weatherId: this.state.cities.length - 1 }, () => this.getWeather())
    }

  }
  incWeatherId = () => {
    if (this.state.weatherId < this.state.cities.length - 1) {
      this.setState({ weatherId: this.state.weatherId + 1 }, () => this.getWeather())
    } else {
      this.setState({ weatherId: 0 }, () => this.getWeather())
    }

  }

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        obj[name] = value
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        obj[name] = value
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          {},
          name,
          value,
          restart
        )
      )
    }
  }

  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          {},
          fields,
          restart
        )
      )
    }
  }

  render() {

    const data = {
      labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      datasets: [
        {
          label: '# of Votes',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    }
    // Chart.defaults.scale.gridLines.drawOnChartArea = false;
    // Chart.defaults.global.legend.display = false;
    const data1 = {
      labels: this.state.logsDate,

      datasets: [
        {
          label: "Broj aktivnih radnika",
          data: this.state.userData,
          fill: true,
          backgroundColor: "rgba(47, 98, 156, 0.5)",
          borderColor: "rgba(47, 98, 156, 1)"
        },
      ],


    };
    const data2 = {
      labels: this.state.logsDate,
      datasets: [
        {
          label: "Broj posjeta stranicama",
          data: this.state.logsData,
          fill: true,
          backgroundColor: "rgba(219, 53, 61, 0.5)",
          borderColor: "rgba(219, 53, 61, 1)",
        },

      ],


    };

    let params = getSearchParams(this.props[0].location.search, {
      entries: 10,
      page: 0,
    })

    console.log(params)

    return (
      <div className='page'>
        {/* {
          typeof localStorage !== 'undefined' && !localStorage.authToken ? <Redirect to='/login' /> : null
        } */}
        <Container className='dashboard padding-top0'>
          <Row>
            <Col lg='9' sm='12' xs='12'>
              <div className="dashboard-content">

                <div class='logo-home'>
                  <img src={logo} className="logo-home1" />
                  <img src={zajednica} className="logo-home2" />
                </div>
                <div className="start-end-picker-wrap">
                  <Input type="date" value={this.state.startDay} onChange={(e) => {
                    let selectedDay = Math.floor(new Date(e.target.value).getTime() / 1000);
                    let endDay = Math.floor(new Date(this.state.endDay).getTime() / 1000);
                    if ((endDay - selectedDay) >= 0) {
                      this.setState({ startDay: e.target.value }, () => this.getStats())
                    } else {
                      this.setState({ startDay: this.state.endDay }, () => this.getStats())
                    }

                  }} />
                  <Input type="date" value={this.state.endDay} onChange={(e) => {
                    let selectedDay = Math.floor(new Date(e.target.value).getTime() / 1000);
                    let startDay = Math.floor(new Date(this.state.startDay).getTime() / 1000);
                    if ((selectedDay - startDay) >= 0) {
                      this.setState({ endDay: e.target.value }, () => this.getStats())
                    } else {
                      this.setState({ endDay: this.state.startDay }, () => this.getStats())
                    }

                  }} />
                  <Input type="select" value={this.state.selectedPosition} onChange={(e) => {
                    this.setState({ selectedPosition: e.target.value }, () => this.getStats())
                  }}>
                    <option value={-1}>Radno mjesto</option>
                    {
                      this.state.positions && this.state.positions.map((item, idx) => {
                        return (
                          <option value={item.value}>{item.name}</option>
                        )
                      })
                    }
                  </Input>
                </div>
                <div className="charts-wrap">
                  <div className="chart-field">
                    <Line data={data1} />
                  </div>
                  <div className="chart-field">
                    <Line data={data2} />
                  </div>

                </div>

              </div>
            </Col>
            <Col lg='3' sm='12' xs='12'>

              <div className="help-bar">
                <div className="help-bar-content">

                  <div className="help-bar-header">
                    <div className='profile'>
                      {
                        this.props.uData ?
                          <>
                            <span className='name'>
                              {this.props.uData && this.props.uData.username}
                            </span>
                            <img src={profile} className='profile-image' />
                          </>
                          :
                          <a href="/login">
                            <span className='name'>
                              Prijavi se
                            </span>
                          </a>
                      }

                    </div>

                    <div className="search-container">
                      <Isvg src={searchIcon} className="search-icon" />
                      <input type="text" className="search" placeholder="Pretraga..." />
                    </div>

                    <div className="weather">
                      <h6>Vremenska prognoza</h6>
                      <div className="weather-content">
                        {/* <Isvg src={weatherIcon} /> */}
                        {
                          this.state.weatherIcon ?
                            <img src={this.state.weatherIcon} alt="" />
                            :
                            null

                        }
                        <div className="weather-info">
                          <span className="temperature">{this.state.temp && this.state.temp} &deg;C  </span> <br />
                          <span className="city">{this.state.city && this.state.city}</span>

                        </div>
                        <div className="next-btn-wrap">
                          <button className="next-btn" onClick={() => this.decWeatherId()}>&lt;</button>
                          <button className="next-btn" onClick={() => this.incWeatherId()}>&gt;</button>
                        </div>
                        {/* <div className="weather-nav">
                                <Isvg src={arrowUp} />
                                <Isvg src={arrowDown} />
                              </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="help-bar-footer">
                    <div className="chart">
                      {/* <Pie data={data} width={240} height={240} options={{ maintainAspectRatio: false, legend: false }} /> */}


                    </div>
                  </div>

                </div>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Page(HomePage)
