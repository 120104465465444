import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import warrning from '../assets/svg/warrning.svg'
import info from '../assets/svg/infoIcon.svg'


/**
* Modal for showing error
* @author   Milan Stanojevic
*/
class ErrorModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} centered size='lg' zIndex={this.props.zIndex ? this.props.zIndex : 1050} className='error-modal'>
                <ModalHeader style={{ margin: 'auto' }}>{'Greška'}</ModalHeader>
                <ModalBody className="delete-modal">
                    {
                        this.props.info ?

                            <img src={info} />

                            :

                            <img src={warrning} />
                    }
                    <div className="message">
                        {
                            this.props.text
                        }
                    </div>
                </ModalBody>
                <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{this.props.closeButtonText ? this.props.closeButtonText : 'Ok'}</Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

export default ErrorModal;