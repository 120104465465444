import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Row, Col
} from 'reactstrap';
import moment from 'moment';

import error from '../assets/images/error.png';

class PreviewModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        console.log(this.props)
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} >

                <ModalBody className="delete-modal">
                    {/* <img src={error} /> */}
                    Log
                    <div className="message">
                        {
                            this.props.children
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    {/* <Button type="submit" color="danger" onClick={() => this.props.handler()}>{'Yes'.translate(this.props.lang)}</Button>{' '}
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'No'.translate(this.props.lang)}</Button>{' '} */}
                    {
                        this.props.item ?
                            <Row>
                                {/* <Col lg="6">Naslov</Col>
                            <Col lg="6">{this.props.item.title}</Col>

                            <Col lg="6">Poruka</Col>
                            <Col lg="6">{this.props.item.message}</Col> */}

                                {
                                    this.props.item.name ?
                                        <>
                                            <Col lg="6">Path</Col>
                                            <Col lg="6">{this.props.item.name}</Col>
                                        </>
                                        :
                                        <>
                                            <Col lg="6">Path</Col>
                                            <Col lg="6">{this.props.item.path}</Col>
                                        </>
                                }


                                <Col lg="6">Username</Col>
                                <Col lg="6">{this.props.item.username}</Col>

                                <Col lg="6">Ime i prezime</Col>
                                <Col lg="6">{this.props.item.firstName} {this.props.item.lastName}</Col>

                                <Col lg="6">Radno mjesto</Col>
                                <Col lg="6">{this.props.item.workPlace}</Col>

                                <Col lg="6">Radna jedinica</Col>
                                <Col lg="6">{this.props.item.workUnit}</Col>

                                <Col lg="6">Sektor</Col>
                                <Col lg="6">{this.props.item.sector}</Col>
                                <br /><br />
                                {
                                    this.props.item.startAbsence ?
                                        <>
                                            <Col lg="6">Početak odsustva</Col>
                                            <Col lg="6">{moment.unix(this.props.item.startAbsence).format('DD.MM.YYYY.')}</Col>
                                        </>
                                        :
                                        null
                                }
                                {
                                    this.props.item.endAbsence ?
                                        <>
                                            <Col lg="6">Zavrsetak odsustva</Col>
                                            <Col lg="6">{moment.unix(this.props.item.endAbsence).format('DD.MM.YYYY.')}</Col>
                                        </>
                                        :
                                        null
                                }
                                {
                                    this.props.item.contactTitle ?
                                        <>
                                            <Col lg="6">Naslov</Col>
                                            <Col lg="6">{this.props.item.contactTitle}</Col>
                                        </>
                                        :
                                        null
                                }
                                {
                                    this.props.item.contactSector ?
                                        <>
                                            <Col lg="6">Sektor</Col>
                                            <Col lg="6">{this.props.item.contactSector}</Col>
                                        </>
                                        :
                                        null
                                }
                                {
                                    this.props.item.contactMessage ?
                                        <>
                                            <Col lg="6">Poruka</Col>
                                            <Col lg="6">{this.props.item.contactMessage}</Col>
                                        </>
                                        :
                                        null
                                }

                                {
                                    this.props.item.poolQuestions ?
                                        <>
                                            <Col lg="6">Pitanje</Col>
                                            <Col lg="6">{this.props.item.poolQuestions}</Col>
                                        </>
                                        :
                                        null
                                }
                                {
                                    this.props.item.poolAnswers && this.props.item.poolAnswers.length ?
                                        <Col lg="12">Odgovori:</Col>

                                        :
                                        null

                                }
                                <Col lg="12">
                                    <div className="answers-pool-wrap">
                                        {
                                            this.props.item.poolAnswers && this.props.item.poolAnswers.map((answer, idx) => {
                                                if (idx == this.props.item.poolVote)
                                                    return (
                                                        <div>
                                                            <strong>{answer}</strong>
                                                        </div>
                                                    )
                                                else
                                                    return (
                                                        <div>
                                                            {answer}
                                                        </div>
                                                    )
                                            })

                                        }
                                    </div>
                                </Col>

                                {
                                    this.props.item.docName ?
                                        <>
                                            <Col lg="6">Naziv dokumenta</Col>
                                            <Col lg="6">{this.props.item.docName}</Col>
                                        </>
                                        :
                                        null

                                }




                            </Row>
                            // <>
                            //     {this.props.item.message}
                            // </>
                            :
                            null
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default PreviewModal;