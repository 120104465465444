import React, { Component } from 'react'
import Page from '../../containers/page'

import { Container, Row, Col, Input } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/formBuilder'
import ErrorModal from '../../components/errorModal';
import { required } from '../../components/forms/validation'
import Search from '../../components/search'

import notification from '../../assets/svg/notification.svg'
import profile from '../../assets/svg/profile-image.svg'
import moreIcon from '../../assets/svg/more.svg'
import rightIcon from '../../assets/svg/arrow-right.svg'

import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../../helpers/linkHelper'
import { API_ENDPOINT } from '../../constants'

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            menu: [],
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.page) {
            this.setState({ lastPage: this.props[0].location.state.page })
        }
        this.get()
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }

    insertOrUpdate = (data) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                if (this.props[0].match.params.id == 'new') {
                    fetch(API_ENDPOINT + '/data/users/new', {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.error) {
                                this.setState({ error: result.error })
                            } else {
                                this.props[0].history.push('/users/list')

                            }
                        })
                } else {
                    fetch(API_ENDPOINT + '/data/users/' + this.props[0].match.params.id, {
                        method: 'PUT',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.error) {
                                this.setState({ error: result.error })
                            } else {
                                this.props[0].history.push({
                                    pathname: '/users/list',
                                    state: { page: this.state.lastPage }
                                })

                            }
                        })
                }
            }
        )
    }

    delete = (id) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                fetch(API_ENDPOINT + '/data/users/' + id, {
                    method: 'DELETE',
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.get()
                    })
            }
        )
    }

    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }
        return (
            <div className='page'>
                {
                    this.state.error ?
                        <ErrorModal
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}
                            text={this.state.error}
                        />
                        :
                        null
                }
                <Container className='dashboard'>

                    {/* <Row>
                        <Col lg='9'>
                            <Search />
                        </Col>
                        <Col lg='3'>
                            <div className='component notifications'>
                                <div className="component-wrap">
                                    <Isvg src={notification} />
                                    <div className='profile'>
                                        <span className='name color-black'>
                                            {this.props.uData.username}
                                        </span>
                                        <Isvg src={profile} className='profile-image' />
                                        <Isvg src={moreIcon} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}

                    <FormBuilder

                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.initialValues}

                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 9
                                        },
                                        children: [{
                                            type: 'div',
                                            className: 'main-form',
                                            children: [{
                                                type: 'row',
                                                children: [
                                                    //HEADER
                                                    {//naslov forme
                                                        type: 'col',
                                                        width: {
                                                            lg: 9, sm: 9, xs: 9
                                                        },
                                                        children: [
                                                            {
                                                                type: 'div',
                                                                className: 'form-header',
                                                                children: [
                                                                    {
                                                                        type: 'h5',
                                                                        className: 'component-header',
                                                                        text: 'Korisnik'
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    },

                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg:3 , sm: 3, xs: 3
                                                        },
                                                        children: [
                                                            {
                                                                type: 'switch',
                                                                name: 'active',
                                                                label: 'Aktivan'
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'firstName',
                                                                label: 'Ime'
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'lastName',
                                                                label: 'Prezime'
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'workPlace',
                                                                label: 'Radno mjesto'
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'workUnit',
                                                                label: 'Radna jedinica'
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'sector',
                                                                label: 'Sektor'
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'username',
                                                                label: 'Username'
                                                            },
                                                        ]
                                                    },
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'password',
                                                                label: 'Password'
                                                            },
                                                        ]
                                                    },

                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 6, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'text',
                                                                name: 'code',
                                                                label: 'Šifra'
                                                            },
                                                        ]
                                                    },



                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {
                                                                type: 'submit-button',
                                                                className: 'main-form-button button-1',
                                                                text: this.props[0].match.params.id != 'new' ? 'Izmjeni korisnika' : 'Dodaj korisnika'
                                                            }
                                                        ]
                                                    },



                                                ],



                                            }]
                                        }
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 3
                                        },
                                        children: [{
                                            type: 'div',
                                            className: 'main-form',
                                            children: [{
                                                type: 'row',
                                                children: [
                                                    
                                                    {
                                                        type: 'col',
                                                        width: {
                                                            lg: 12, sm: 12, xs: 12
                                                        },
                                                        children: [
                                                            {//Slike
                                                                type: 'div',
                                                                className: 'component gallery',
                                                                children: [
                                                                    {
                                                                        type: 'h5',
                                                                        className: 'component-header',
                                                                        text: 'Fotografija',
                                                                    },
                                                                    {
                                                                        type: 'image',
                                                                        height: 300,
                                                                        name: 'image'
                                                                    }
                                                                ]
                                                            },
                                                        ]
                                                    },



                                                ],



                                            }]
                                        }
                                        ]
                                    },
                                ]
                            }
                        ]}
                    ></FormBuilder>
                </Container>
            </div >
        )
    }
}

export default Page(Form)
