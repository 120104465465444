import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Row, Col
} from 'reactstrap';
import moment from 'moment';

import error from '../assets/images/error.png';

class PreviewModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        console.log(this.props)
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-contact-dialog">

                <ModalBody className="delete-modal">
                    {/* <img src={error} /> */}
                    PRIJEDLOG
                    <div className="message">
                        {
                            this.props.children
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    {/* <Button type="submit" color="danger" onClick={() => this.props.handler()}>{'Yes'.translate(this.props.lang)}</Button>{' '}
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'No'.translate(this.props.lang)}</Button>{' '} */}
                    {
                        this.props.item ? 
                        <Row>
                            <Col lg="6" className="modal-col-margin">Naslov</Col>
                            <Col lg="6" className="modal-col-margin">{this.props.item.title}</Col>

                            <Col lg="6" className="modal-col-margin">Username posiljaoca poruke</Col>
                            <Col lg="6" className="modal-col-margin">{this.props.item.username}</Col>

                            <Col lg="6" className="modal-col-margin">Sektor</Col>
                            <Col lg="6" className="modal-col-margin">{this.props.item.sectorName}</Col>

                            <Col lg="6" className="modal-col-margin">Poruka</Col>
                            <Col lg="6" className="modal-col-margin">{this.props.item.message}</Col>

                           
                        </Row>
                        // <>
                        //     {this.props.item.message}
                        // </>
                        :
                        null
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default PreviewModal;