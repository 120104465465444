import HomePage from './views/homePage'

import Login from './views/loginPage'

import { API_ENDPOINT } from './constants'

import news from './views/news'
import addNews from './views/addNews';
import addCategory from './views/addCategory';
import category from './views/category';


import MenuList from './views/menu/list';
import MenuForm from './views/menu/form';

import NewsCategoriesList from './views/newsCategories/list';
import NewsCategoriesForm from './views/newsCategories/form';

import NewsArticlesList from './views/newsArticles/list';
import NewsArticlesForm from './views/newsArticles/form';

import NewEmployeesList from './views/newEmployees/list';
import NewEmployeesForm from './views/newEmployees/form';

import DocumentsList from './views/documents/list';
import DocumentsForm from './views/documents/form';

import PoolsList from './views/pools/list';
import ListOfComments from './views/pools/listOfComments';
import PoolsForm from './views/pools/form';

import AdminsList from './views/admins/list';
import AdminsForm from './views/admins/form';


import UsersList from './views/users/list';
import UsersForm from './views/users/form';

import PrecautionsList from './views/precautions/list';
import PrecautionsForm from './views/precautions/form';

import LibraryList from './views/library/list';
import LibraryForm from './views/library/form';


import SettingsForm from './views/settings/form';
import DeliveryLines from './views/deliveryLines/form';

import Absence from './views/absences/absence';
import Logs from './views/logs/logs';
import Suggestions from './views/suggestions/list';
import ForgetPassword from './views/forgetPassword/list';

import PoolsImagesList from './views/poolsImages/list';
import ListOfCommentsPoolsImages from './views/poolsImages/listOfComments';
import PoolsImagesForm from './views/poolsImages/form';

import PoolsVideoList from './views/poolsVideo/list';
import ListOfCommentsPoolsVideo from './views/poolsVideo/listOfComments';
import PoolsVideoForm from './views/poolsVideo/form';


import ShopCategoriesList from './views/shopCategories/list';
import ShopCategoriesForm from './views/shopCategories/form';

import ShopArticlesList from './views/shopArticles/list';
import ShopArticlesForm from './views/shopArticles/form';

import ShopOrdersList from './views/shopOrders/list';


function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    }
  })
}

export const routes = [
  {
    path: '/',
    component: HomePage,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [

    ],
  },

  {
    path: '/menu/list',
    component: MenuList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/menu', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/menu/:id',
    component: MenuForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },

  {
    path: '/shop/categories/list',
    component: ShopCategoriesList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/shop/categories', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/shop/categories/:id',
    component: ShopCategoriesForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/shop/categories/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },


  {
    path: '/news/categories/list',
    component: NewsCategoriesList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news/categories', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/news/categories/:id',
    component: NewsCategoriesForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/categories/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },

  {
    path: '/shop/orders/list',
    component: ShopOrdersList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/shop/orders', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: 'timestamp',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/shop/articles/list',
    component: ShopArticlesList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/shop/articles', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: 'timestamp',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/shop/articles/:id',
    component: ShopArticlesForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/shop/categories/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              categories: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/shop/articles/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },
  {
    path: '/news/articles/list',
    component: NewsArticlesList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news/articles', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/news/articles/:id',
    component: NewsArticlesForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/categories/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              categories: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/articles/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },
  {
    path: '/documents/list',
    component: DocumentsList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/documents', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: '-1',
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/documents/:id',
    component: DocumentsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/documents/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },

  {
    path: '/pools/comments/:id',
    component: ListOfComments,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + `/data/pools/comments/${match.params.id}`, {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/pools/list',
    component: PoolsList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/pools', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: '-1',
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/pools/:id',
    component: PoolsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/pools/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },




  {
    path: '/pools-images/comments/:id',
    component: ListOfCommentsPoolsImages,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + `/data/pools-images/comments/${match.params.id}`, {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/pools-images/list',
    component: PoolsImagesList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/pools-images', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: '-1',
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/pools-images/:id',
    component: PoolsImagesForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/pools-images/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },
  {
    path: '/pools-video/comments/:id',
    component: ListOfCommentsPoolsVideo,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + `/data/pools-video/comments/${match.params.id}`, {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/pools-video/list',
    component: PoolsVideoList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/pools-video', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: '-1',
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/pools-video/:id',
    component: PoolsVideoForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/pools-video/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },




  {
    path: '/admins/list',
    component: AdminsList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/admins', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/admins/:id',
    component: AdminsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/admins/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },


  {
    path: '/users/list',
    component: UsersList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/users', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/users/:id',
    component: UsersForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/users/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },

  {
    path: '/delivery-lines',
    component: DeliveryLines,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/deliveryLines', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)

            return {
              initialValues: result,
            }
        })
      }
    ],
    loadDataWithQuery: [

    ],
  },

  {
    path: '/settings',
    component: SettingsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/home', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)

            return {
              initialValues: result,
            }
        })
      }
    ],
    loadDataWithQuery: [

    ],
  },


  {
    path: '/suggestions',
    component: Suggestions,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/suggestions', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {

    path: '/forgotten-password',
    component: ForgetPassword,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/forgotten-pasword', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/absence',
    component: Absence,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/absence', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/users/logs/:id/:date',
    component: Logs,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/logs/' + match.params.id + "/" + match.params.date, {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: 'timestamp',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },



  {
    path: '/login',
    component: Login,
    redirectUser: '/',
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
  },

  {
    path: '/precautions/list',
    component: PrecautionsList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news/precautions', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/precaution/precautions/:id',
    component: PrecautionsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/precautions/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              categories: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/precautions/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },
  {
    path: '/news/employees/list',
    component: NewEmployeesList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news/employees', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/news/employees/:id',
    component: NewEmployeesForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [

      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/employees/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },


  {
    path: '/news/library/list',
    component: LibraryList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/news/library', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/news/library/:id',
    component: LibraryForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'Interni Portal '.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [

      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/news/library/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },

]

export function generateRoutes(r) {
  let newRoutes = []
  for (let i = 0; i < r.length; i++) {
    newRoutes.push(r[i])

    /*newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })*/
  }
  return newRoutes
}
