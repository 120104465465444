import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Row, Col
} from 'reactstrap';
import moment from 'moment';

import error from '../assets/images/error.png';

class PreviewModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        console.log(this.props)
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} >

                <ModalBody className="delete-modal">
                    {/* <img src={error} /> */}
                    Zahtjev za odsustvo
                    <div className="message">
                        {
                            this.props.children
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    {/* <Button type="submit" color="danger" onClick={() => this.props.handler()}>{'Yes'.translate(this.props.lang)}</Button>{' '}
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'No'.translate(this.props.lang)}</Button>{' '} */}
                    {
                        this.props.item ? 
                        <Row>
                            {/* <Col lg="6">Naslov</Col>
                            <Col lg="6">{this.props.item.title}</Col>

                            <Col lg="6">Poruka</Col>
                            <Col lg="6">{this.props.item.message}</Col> */}

                            <Col lg="6">Pocetak odsustva</Col>
                            <Col lg="6">{moment.unix(this.props.item.startTS).format('DD.MM.YYYY.')}</Col>

                            <Col lg="6">Zavrsetak odsustva</Col>
                            <Col lg="6">{moment.unix(this.props.item.endTS).format('DD.MM.YYYY.')}</Col>

                            <Col lg="6">Username</Col>
                            <Col lg="6">{this.props.item.username}</Col>

                            <Col lg="6">Ime i prezime</Col>
                            <Col lg="6">{this.props.item.firstName} {this.props.item.lastName}</Col>

                            <Col lg="6">Radno mjesto</Col>
                            <Col lg="6">{this.props.item.workPlace}</Col>

                            <Col lg="6">Radna jedinica</Col>
                            <Col lg="6">{this.props.item.workUnit}</Col>

                            <Col lg="6">Sektor</Col>
                            <Col lg="6">{this.props.item.sector}</Col>

                            {/* <Col lg="6">Status</Col>
                            <Col lg="6">{this.props.item.status == 0 ? "Na cekanju" : this.props.item.status == 1 ? "Odobreno" : "Odbijeno"}</Col> */}

                            {/* <Col lg="6">Status izmjenjen</Col>
                            <Col lg="6">{moment.unix(this.props.item.statusModified).format('DD.MM.YYYY. HH:mm')}h</Col> */}
                        </Row>
                        // <>
                        //     {this.props.item.message}
                        // </>
                        :
                        null
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default PreviewModal;